import dynamic from 'next/dynamic'
import CurrentHarvest from '../../Harvest/CurrentHarvest'
import StaticHarvest from '@/components/Harvest/StaticHarvest'
import ProjectedHarvest from '../../Harvest/ProjectedHarvest'
import FeaturedBlogContent from '@/components/Sections/FeaturedBlogContent'
import FullBleedHero from '@/components/Sections/FullBleedHero'
import SplitHero from '@/components/Sections/SplitHero'
import FiftyFifty from '@/components/Sections/FiftyFifty'
import PressLogos from '@/components/Sections/PressLogos'
import ValueProps from '@/components/Sections/ValueProps'
import FAQs from '@/components/Sections/FAQs'
import BlogHero from '@/components/Sections/BlogHero'
import RecipeCategoriesList from '@/components/Sections/RecipeCategoriesList'
import HalfHeroHalfSlider from '@/components/Sections/HalfHeroHalfSlider'
import EmailSignupBlock from '@/components/Sections/EmailSignupBlock'
import BrowseCategory from '@/components/Sections/BrowseCategory'
import ArticleRow from '@/components/Sections/ArticleRow'
import HalfVideoBlock from '@/components/Sections/HalfVideoBlock'
import FeaturedFishCarousel from '@/components/Sections/FeaturedFishCarousel'
import AllKnowYourFishblock from '@/components/Sections/AllKnowYourFishBlock'
import FeaturedText from '@/components/Sections/FeaturedText'
import FiftyFiftyImage from '@/components/Sections/FiftyFiftyImage'
import BoldHeaderText from '@/components/Sections/BoldHeaderText'
import ImageWithText from '@/components/Sections/ImageWithText'
import ProductHero from '@/components/Sections/ProductHero'
import ProductGallery from '@/components/Sections/ProductGallery'
import ImageTextColumns from '../ImageTextColumns'
import WYSIWYG from '@/components/Sections/WYSIWYG'
import ContactUs from '@/components/Sections/ContactUs'
import Accordion from '../Accordion'
const ReviewsCarousel = dynamic(() => import('@/components/Sections/ReviewsCarousel'), {
  ssr: false
});
import FishermenPartners from '../FishermenPartners'
import ColumnSection from '../ColumnSection'
import BannerBlock from '../BannerBlock'
import FeaturedProductBlock from '../FeaturedProductBlock'
import CollectionHero from '@/components/Sections/CollectionHero'
import CollectionList from '@/components/Sections/CollectionList'
import FeaturedSubscriptionBlock from '../FeaturedSubscriptionBlock'
import FullBleedVideo from '@/components/Sections/FullBleedVideo/FullBleedVideo'
import GenericHero from '@/components/Sections/GenericHero'
import { ImageRevealSection } from '@/components/Sections/ImageRevealSection'
import FullBleedQuestionnaire from '../FullBleedQuestionnaire'

const ContentSections = ({
  sections,
  harvestsLoading,
  harvests,
  disableHarvestFilters,
  product,
  variant,
  setSelectedVariant,
  productPurchaseOptions,
}) => {
  if (!Array.isArray(sections)) {
    return null
  }

  return sections.map((section, index) => {
    const type = section?._type

    const imagePriority = index < 2

    switch (type) {
      case 'hero':
        return (
          <FullBleedHero
            fields={section}
            imagePriority={imagePriority}
            key={section._key}
          />
        )
      case 'splitHero':
        return (
          <SplitHero
            fields={section}
            imagePriority={imagePriority}
            key={section._key}
          />
        )
      case 'halfContentBlock':
        return <FiftyFifty fields={section} key={section._key} />
      case 'pressLogos':
        return <PressLogos fields={section} key={section._key} />
      case 'valueProps':
        return <ValueProps fields={section} key={section._key} />
      case 'featuredBlogContent':
        return <FeaturedBlogContent fields={section} key={section._key} />
      case 'currentSellingHarvest':
        return <CurrentHarvest fields={section} key={section._key} />
      case 'currentMonthHarvest':
        return <CurrentHarvest fields={section} key={section._key} />
      case 'projectedHarvest':
        if (harvests) {
          section = {
            ...section,
            harvestList: harvests,
          }
        }
        return (
          <ProjectedHarvest
            harvestsLoading={harvestsLoading}
            fields={section}
            disableHarvestFilters={disableHarvestFilters}
            key={section._key}
          />
        )
      case 'staticHarvest':
        return <StaticHarvest fields={section} key={section._key} />
      case 'globalSampler':
        return <CurrentHarvest fields={section} key={section._key} />
      case 'faqs':
        return <FAQs fields={section} key={section._key} />
      case 'blogHero':
        return (
          <BlogHero
            fields={section}
            imagePriority={imagePriority}
            key={section._key}
          />
        )
      case 'recipeCategoriesList':
        return <RecipeCategoriesList fields={section} key={section._key} />
      case 'halfHeroHalfSlider':
        return (
          <HalfHeroHalfSlider
            fields={section}
            imagePriority={imagePriority}
            key={section._key}
          />
        )
      case 'emailSignup':
        return (
          <div className="bg-color--fawn">
            <div className="container">
              <EmailSignupBlock
                props={{
                  emailSignup: section,
                }}
                key={section._key}
              />
            </div>
          </div>
        )
      case 'browseCategory':
        return <BrowseCategory fields={section} key={section._key} />
      case 'articleRow':
        return <ArticleRow fields={section} key={section._key} />
      case 'recipeArticleRow':
        return <ArticleRow fields={section} key={section._key} />
      case 'halfVideoBlock':
        return <HalfVideoBlock fields={section} key={section._key} />
      case 'featuredText':
        return <FeaturedText fields={section} key={section._key} />
      case 'fiftyFiftyImageBlock':
        return <FiftyFiftyImage fields={section} key={section._key} />
      case 'boldHeaderText':
        return <BoldHeaderText fields={section} key={section._key} />
      case 'imageWithText':
        return <ImageWithText fields={section} key={section._key} />
      case 'imageTextColumns':
        return <ImageTextColumns fields={section} key={section._key} />
      case 'wysiwyg':
        return <WYSIWYG fields={section} key={section._key} />
      case 'contactUs':
        return <ContactUs fields={section} key={section._key} />
      case 'accordion':
        return <Accordion fields={section} key={section._key} />
      case 'featuredFishCarousel':
        return <FeaturedFishCarousel fields={section} key={section._key} />
      case 'featuredFishermenCarousel':
        return <FeaturedFishCarousel fields={section} key={section._key} />
      case 'allKnowYourFishBlock':
        return <AllKnowYourFishblock fields={section} key={section._key} />
      case 'reviewsCarousel':
        return <ReviewsCarousel fields={section} key={section._key} />
      case 'fishermenPartners':
        return <FishermenPartners fields={section} key={section._key} />
      case 'productReviews':
        if (product) {
          return <ReviewsCarousel fields={{ products: [product] }} />
        }
        return null
      case 'columnLayout':
        return (
          <ColumnSection
            columns={section.columns}
            key={section._key}
          ></ColumnSection>
        )
      case 'bannerBlock':
        return <BannerBlock fields={section} key={section._key} />
      case 'featuredProductBlock':
        return <FeaturedProductBlock fields={section} key={section._key} />
      case 'collectionHero':
        return <CollectionHero fields={section} key={section._key} />
      case 'collectionList':
        return <CollectionList fields={section} key={section._key} />
      case 'productHero':
        return (
          <ProductHero
            fields={section}
            key={section._key}
            product={product}
            selectedVariant={variant}
            setSelectedVariant={setSelectedVariant}
            productPurchaseOptions={productPurchaseOptions}
          />
        )
      case 'productGallery':
        return (
          <ProductGallery
            fields={section}
            key={section._key}
            product={product}
          />
        )
      case 'featuredSubscriptionBlock':
        return <FeaturedSubscriptionBlock fields={section} key={section._key} />
      case 'fullBleedVideo':
        return <FullBleedVideo fields={section} key={section._key} />
      case 'imageReveal':
        return <ImageRevealSection fields={section} key={section._key} />
      case 'genericSection':
        return <GenericHero fields={section} key={section._key} />
      case 'questionnaireHero':
        return (
          <FullBleedQuestionnaire
            fields={section}
            imagePriority={imagePriority}
            key={section._key}
          />
        )
      default:
        return null
    }
  })
}

export default ContentSections
